/** @jsxRuntime classic */
/** @jsx jsx */
import {
  Box,
  Flex,
  LockClosedIcon,
  Text,
  Title,
} from '@bottlebooks/gatsby-theme-base';
import useAuthentication from '@bottlebooks/gatsby-theme-event/plugins/gatsby-plugin-firebase-auth/src/useAuthentication';
import { Bottom } from '@bottlebooks/gatsby-theme-event/src/components/Layout/Footer';
import ProductsList from '@bottlebooks/gatsby-theme-event/src/components/ProductsList/ProductsList';
import useLink from '@bottlebooks/gatsby-theme-event/src/useLink';
import { getApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { graphql } from 'gatsby';
import { useEffect, useState } from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { jsx } from 'theme-ui';
import ProductCard from '../../../../../@bottlebooks/gatsby-theme-event/components/ProductCard/ProductCard';
import EmbedLayout from '../../../../../components/EmbedLayout';

export default function BrellaExhibitorProductsPage(props) {
  const { isAuthenticated } = useAuthentication();
  const exhibitor = afterQuery(props?.data);

  if (!isAuthenticated())
    return (
      <SidebarLayout>
        <UnlockContent {...props?.data.bottlebooks.event} />
      </SidebarLayout>
    );

  return (
    <SidebarLayout>
      <ExhibitorSidebar {...exhibitor} sx={{ flexGrow: 1 }} />;
    </SidebarLayout>
  );
}

/**
 * We want to proactively inform the user that they need to be
 * logged in to their account. Otherwise, they it seems like an
 * error when the start to add products to their list from
 * Brella.
 * @param {*} param0
 * @returns
 */
function UnlockContent({ logo }) {
  const [widget, setWidget] = useState(null);

  const auth = getAuth(getApp());
  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // We will display Google as auth provider.
    signInOptions: ['google.com', 'password'],
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: () => {
        // Do nothing
      },
    },
  };
  // OMG This is a horrible hack
  // https://github.com/firebase/firebaseui-web-react/issues/59
  // The maintainer hasn't merged the PR yet:
  // https://github.com/firebase/firebaseui-web-react/pull/173
  useEffect(() => {
    setWidget(<StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />);
  }, []);
  return (
    <Flex direction="column" sx={{ height: '100vh', padding: 4 }}>
      <Flex
        direction="column"
        justify="center"
        align="center"
        sx={{ marginY: 4, width: '100%' }}
      >
        <LockClosedIcon size="medium" sx={{ marginBottom: 3 }} />
        <Title variant="small">Unlock wines</Title>

        <Text sx={{ marginTop: 2, textAlign: 'center', maxWidth: 400 }}>
          Log in to your personalized tasting list to view wines. This allows
          you to create a list or add tasting notes and access it on any device.
        </Text>
        <Box> {widget}</Box>
        <img {...logo.fixed} />
      </Flex>
    </Flex>
  );
}

function SidebarLayout({ children, ...rest }) {
  return (
    <EmbedLayout sx={{ display: 'flex', flexDirection: 'column' }}>
      {children}
      <Bottom />
    </EmbedLayout>
  );
}

function ExhibitorSidebar({ products, ...rest }) {
  const link = useLink();
  return (
    <ProductsList {...rest}>
      {products?.map((product) => (
        <ProductCard
          key={product.id}
          product={product}
          to={link.product(product)}
          sx={{ marginBottom: [null, null, 3] }}
        />
      ))}
    </ProductsList>
  );
}

export const pageQuery = graphql`
  query BrellaExhibitorProducts($partnerId: ID!) {
    bottlebooks {
      event(eventId: "62ecd29062842e10d25d8961", locale: en) {
        logo {
          fixed(width: 150) {
            src
            width
            height
          }
        }
      }
      oct31Morning: event(eventId: "62ecd29062842e10d25d8961", locale: en) {
        ...Bottlebooks_ExhibitorProducts
      }
      oct31Afternoon: event(eventId: "62ecd2b3f28b1e10cc33eb5d", locale: en) {
        ...Bottlebooks_ExhibitorProducts
      }
      nov1Morning: event(eventId: "62ecd2fe123f1410cadbbba4", locale: en) {
        ...Bottlebooks_ExhibitorProducts
      }
      nov1Afternoon: event(eventId: "62ecd313f394f910d1d6d29f", locale: en) {
        ...Bottlebooks_ExhibitorProducts
      }
    }

    exhibitor(exhibitorId: { eq: $partnerId }) {
      name
      products {
        productId
        ...ExhibitorPage_Product
        ...ProductImageLarge
      }
      event {
        name
        eventId
      }
    }
  }
  fragment Bottlebooks_ExhibitorProducts on Bottlebooks_Event {
    collectionId
    exhibitor(exhibitorId: $partnerId, returnNullWhenNotFound: true) {
      stand {
        name
      }
      products {
        nodes {
          productId
          ...bb_ProductCard
          ... on Bottlebooks_Wine {
            shortName
            vintage
            grapeVarieties {
              varietyName
              percentage
            }
          }
          producer {
            name
          }
        }
      }
    }
  }
`;

function afterQuery(data) {
  const { bottlebooks } = data;
  const events = Object.values(bottlebooks);
  const bb_exhibitor = mergeBbExhibitor(events);
  const bb_products = bb_exhibitor?.products?.nodes;
  return {
    products: bb_products,
  };
}

function mergeBbExhibitor(events) {
  const exhibitor = events.reduce((acc, event) => {
    const { exhibitor, registration } = event;
    if (!exhibitor) return acc;

    const { exhibitorId } = exhibitor;

    acc[exhibitorId] = {
      ...exhibitor,
      collectionIds: [event.collectionId],
      ...registration,
      ...registration?.profile,
      standNames: [
        ...(acc[exhibitorId]?.standNames || []),
        {
          collectionId: event.collectionId,
        },
      ],
      products: {
        nodes: [
          ...(acc[exhibitorId]?.products?.nodes || []),
          ...exhibitor.products.nodes.map((product) => ({
            ...product,
            collectionId: event.collectionId,
            standNames: [{ collectionId: event.collectionId }],
          })),
        ],
      },
    };
    return acc;
  }, {});

  return Object.values(exhibitor)[0];
}
